import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Sounds'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><inlineCode parentName="h1">{`@arwes/sounds`}</inlineCode></h1>
    <p>{`Define, manage, and control interactive sound effects using `}<a parentName="p" {...{
        "href": "https://howlerjs.com"
      }}>{`Howler`}</a>{`
in `}<a parentName="p" {...{
        "href": "https://reactjs.org"
      }}>{`React`}</a>{`.`}</p>
    <p>{`Based on the `}<a parentName="p" {...{
        "href": "/project/guidelines"
      }}>{`UI/UX guidelines`}</a>{`, the sounds tools can be
used for application interface sounds management. Futuristic science fiction
user interfaces usually execute short experiential sounds such as beeps, bleeps,
processing loops, glitches, and chimes, on `}<a parentName="p" {...{
        "href": "/project/guidelines/events"
      }}>{`events`}</a>{`
or `}<a parentName="p" {...{
        "href": "/project/guidelines/states"
      }}>{`state transitions`}</a>{`.`}</p>
    <p>{`These tools are not intended for sounds with long durations such as music songs.`}</p>
    <p>{`The sounds tools require the `}<a parentName="p" {...{
        "href": "https://reactjs.org"
      }}>{`React`}</a>{` component tree to
allow the dynamic communication between components and `}<a parentName="p" {...{
        "href": "https://howlerjs.com"
      }}>{`Howler`}</a>{`
for sound management.`}</p>
    <p>{`It goes hand in hand with the `}<a parentName="p" {...{
        "href": "/develop/animation"
      }}>{`animation tools`}</a>{`, though
it is not a dependency.`}</p>
    <p>{`It is advised to follow the `}<a parentName="p" {...{
        "href": "/develop/sounds/accessibility"
      }}>{`Sound Accessibility`}</a>{`
recommendations.`}</p>
    <h2>{`Installation`}</h2>
    <p>{`All the tools are bundled and can be installed with the following NPM package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @arwes/sounds
`}</code></pre>
    <p>{`The sounds management tooling requires React v17 and Howler v2.2 as peer-dependencies.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install react@17 react-dom@17 prop-types howler@2.2
`}</code></pre>
    <p>{`In TypeScript, the following type packages are needed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @types/react@17 @types/react-dom@17 @types/howler@2.2
`}</code></pre>
    <h2>{`Bleeps`}</h2>
    <p>{`A short sound used in Arwes application components is called a bleep. Bleeps can
be used on user events such as user interactions, application events such as
notifications, or animation flow transitions on specific components along with
the `}<a parentName="p" {...{
        "href": "/develop/animation"
      }}>{`animation tools`}</a>{`.`}</p>
    <h2>{`Providers`}</h2>
    <p>{`Since bleeps in an application are shared among its components, there needs to be
a global data provider of the audio settings, player settings, and bleeps settings.
With the configured settings, the provider creates the bleeps to provide to the
components.`}</p>
    <h3>{`Audio Settings`}</h3>
    <p>{`The audio settings define the general audio sound settings which are normally
transversal for sound control in an application. They can be applicable to all bleeps
and to `}<a parentName="p" {...{
        "href": "/develop/sounds/categories"
      }}>{`categories of bleeps`}</a>{`.`}</p>
    <p>{`For example, enabling all sounds with an specific volume can be set in a application
component like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React, { FC } from 'react';
import { BleepsProvider, BleepsAudioSettings } from '@arwes/sounds';

const audioSettings: BleepsAudioSettings = {
  common: {
    volume: 0.5, // default 1
    disabled: false // default false
  }
};

const App: FC = ({ children }) => (
  <BleepsProvider audioSettings={audioSettings}>
    {children}
  </BleepsProvider>
);
`}</code></pre>
    <p>{`Sound volume can go from `}<inlineCode parentName="p">{`0`}</inlineCode>{` to `}<inlineCode parentName="p">{`1`}</inlineCode>{`.`}</p>
    <p>{`If sounds are disabled, no bleeps are passed to the components.`}</p>
    <h3>{`Players Settings`}</h3>
    <p>{`A player settings define the sound file data.`}</p>
    <p>{`In an application component example, the players could be configured like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React, { FC } from 'react';
import { BleepsProvider, BleepsPlayersSettings } from '@arwes/sounds';

const playersSettings: BleepsPlayersSettings = {
  click: { // On user click.
    src: ['/path/to/sounds/click.mp3']
  },
  type: { // Text typing loop sound.
    src: ['/path/to/sounds/type.mp3'],
    loop: true
  }
};

const App: FC = ({ children }) => (
  <BleepsProvider playersSettings={playersSettings}>
    {children}
  </BleepsProvider>
);
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`click`}</inlineCode>{` player will use the file `}<inlineCode parentName="p">{`click.mp3`}</inlineCode>{` in MP3 format.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`type`}</inlineCode>{` player will use the file `}<inlineCode parentName="p">{`type.mp3`}</inlineCode>{` in MP3 format as a loop sound.
It means that the player will play in a loop until explicitely stopped.`}</p>
    <h2>{`Bleeps Settings`}</h2>
    <p>{`The bleeps settings define which player to use and audio settings to use.`}</p>
    <p>{`Based on the application example players settings, the bleeps can be
configured like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React, { FC } from 'react';
import { BleepsProvider, BleepsSettings } from '@arwes/sounds';

const bleepsSettings: BleepsSettings = {
  tap: {
    player: 'click'
  },
  typing: {
    player: 'type'
  }
};

const App: FC = ({ children }) => (
  <BleepsProvider bleepsSettings={bleepsSettings}>
    {children}
  </BleepsProvider>
);
`}</code></pre>
    <p>{`The bleep names can be the same or different from the player names.
There can be multiple bleeps based on the same player settings.`}</p>
    <p>{`The bleeps can also have `}<a parentName="p" {...{
        "href": "/develop/sounds/categories"
      }}>{`categories`}</a>{`.`}</p>
    <h2>{`Consumers`}</h2>
    <p>{`A React component can consume bleeps using the `}<inlineCode parentName="p">{`useBleeps`}</inlineCode>{` React hook.`}</p>
    <p>{`It requires a parent `}<inlineCode parentName="p">{`BleepsProvider`}</inlineCode>{` with bleeps settings to receive the
generated bleeps. In case there is no provider found, an empty bleeps object
is returned.`}</p>
    <p>{`A bleep is a sound player and implements the audio settings applicable.`}</p>
    <p>{`Assuming a `}<inlineCode parentName="p">{`tap`}</inlineCode>{` bleep provided by a parent provider, a button component can
use it like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React, { FC } from 'react';
import { useBleeps } from '@arwes/sounds';

const ButtonComponent: FC = ({ children }) => {
  const bleeps = useBleeps();
  const onClick = () => bleeps.tap.play();
  return (
    <button onClick={onClick}>
      {children}
    </button>
  );
};
`}</code></pre>
    <p>{`A complete example can look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-arwes_sandbox"
      }}>{`const SOUND_CLICK_URL = 'https://playground.arwes.dev/assets/sounds/click.mp3';

const Button = ({ children }) => {
  const bleeps = useBleeps();
  const onClick = () => bleeps.tap.play();
  return <button onClick={onClick}>{children}</button>;
};

function App () {
  const audioSettings = {
    common: {
      volume: 0.5
    }
  };
  const playersSettings = {
    click: {
      src: [SOUND_CLICK_URL]
    }
  };
  const bleepsSettings = {
    tap: {
      player: 'click'
    }
  };

  return (
    <BleepsProvider
      audioSettings={audioSettings}
      playersSettings={playersSettings}
      bleepsSettings={bleepsSettings}
    >
      <Button>Bleep!</Button>
    </BleepsProvider>
  );
}

render(<App />);
`}</code></pre>
    <p>{`If the applicable audio settings disable the bleep, the sound will not be available
in the component. So if the component should work with enabled/disabled bleeps,
it should check if it exists before using it. You may use
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining"
      }}>{`optional chaining`}</a>{`
for that.`}</p>
    <p>{`If the bleep is enabled and the player `}<inlineCode parentName="p">{`click`}</inlineCode>{` does not exist, it will throw an error.`}</p>
    <h2>{`Shared Sounds`}</h2>
    <p>{`By default, bleeps are shared by all components. It means that if any of the
component instances try to play or stop the sound, it would be the same
bleep sound. The same goes for all the other bleep API methods.`}</p>
    <p>{`Since an intensive application can have hundreds or thousands of component instances
with different sounds, if the sounds were created each time, it would highly
compromise performance.`}</p>
    <h2>{`Preloading`}</h2>
    <p>{`Bleeps are preloaded on creation time, not on definition time. So the sounds
would be loaded when the provider is mounted.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Functionalities for custom sound loading are still in progress.`}</p>
    </blockquote>
    <h2>{`Recommended Audio Formats`}</h2>
    <p>{`As recommended by `}<a parentName="p" {...{
        "href": "https://github.com/goldfire/howler.js#format-recommendations"
      }}>{`howlerjs`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"If your goal is to have the best balance of small filesize and high quality,
based on extensive production testing, your best bet is to default to `}<inlineCode parentName="p">{`webm`}</inlineCode>{` and
fallback to `}<inlineCode parentName="p">{`mp3`}</inlineCode>{`. `}<inlineCode parentName="p">{`webm`}</inlineCode>{` has nearly full browser coverage with a great combination
of compression and quality. You'll need the `}<inlineCode parentName="p">{`mp3`}</inlineCode>{` fallback for Internet Explorer."`}</p>
    </blockquote>
    <p>{`Since Arwes is not supported in Internet Explorer out of the box, the recommendation
is to use `}<inlineCode parentName="p">{`webm`}</inlineCode>{` audio format.`}</p>
    <hr></hr>
    <p>{`You can see and play with more examples in the `}<a parentName="p" {...{
        "href": "https://playground.arwes.dev/sounds/BleepsProvider/basic"
      }}>{`playground`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      